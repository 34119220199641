import { render, staticRenderFns } from "./active-icon-watermark.component.vue?vue&type=template&id=dddd3418&scoped=true&"
import script from "./active-icon-watermark.component.vue?vue&type=script&lang=ts&setup=true&"
export * from "./active-icon-watermark.component.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./active-icon-watermark.component.vue?vue&type=style&index=0&id=dddd3418&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dddd3418",
  null
  
)

export default component.exports